var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"800px","scrollable":""},on:{"keydown":_vm.executeCloseModalCreateMaterial,"click:outside":_vm.executeCloseModalCreateMaterial},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" "+_vm._s(_vm.currentFormMaterial ? "Actualizar Material" : "Agregar nuevo Código")+" ")])]),_c('hr'),_c('v-card-text',[_c('v-container',{},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendAddMaterial.apply(null, arguments)}}},[_c('v-row',{attrs:{"align-content":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"description"}},[_vm._v(" Descripción * ")]),_c('v-textarea',{attrs:{"rows":"4","counter":"","outlined":"","placeholder":"Descripción del articulo","error":_vm.$v.form.description.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.description.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null},model:{value:(_vm.$v.form.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.description, "$model", $$v)},expression:"$v.form.description.$model"}})],1),(_vm.showAllForm)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('label',{staticClass:"mt-1"},[_vm._v(" Grupo de Articulo * ")]),_c('v-select',{attrs:{"placeholder":"Grupo del articulo","error":_vm.$v.form.group_article.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.group_article.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.allGroupArticles},model:{value:(_vm.$v.form.group_article.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.group_article, "$model", $$v)},expression:"$v.form.group_article.$model"}})],1):_vm._e(),(_vm.showAllForm)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('label',{staticClass:"mt-1"},[_vm._v(" Unidad de Medida * ")]),_c('v-select',{attrs:{"placeholder":"Unidad de medida del articulo","error":_vm.$v.form.measurement_unit.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.measurement_unit.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.allUnits.filter(function (unit) { return unit; })},model:{value:(_vm.$v.form.measurement_unit.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.measurement_unit, "$model", $$v)},expression:"$v.form.measurement_unit.$model"}})],1):_vm._e(),(_vm.showAllForm)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('label',{attrs:{"for":"family"}},[_vm._v(" Familia * ")]),_c('v-select',{attrs:{"placeholder":"Familia del articulo","error":_vm.$v.form.family.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.family.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.allFamilies},model:{value:(_vm.$v.form.family.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.family, "$model", $$v)},expression:"$v.form.family.$model"}})],1):_vm._e(),(_vm.showAllForm)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('label',{attrs:{"for":"expense_item"}},[_vm._v(" Item de Gasto * ")]),_c('v-select',{attrs:{"placeholder":"Selecciona el item de gasto","error":_vm.$v.form.expense_item.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.expense_item.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.allExpenseItems},model:{value:(_vm.$v.form.expense_item.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.expense_item, "$model", $$v)},expression:"$v.form.expense_item.$model"}})],1):_vm._e(),(_vm.showAllForm)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"type_destiny"}},[_vm._v(" Ceco / Proyecto * ")]),_c('v-select',{attrs:{"placeholder":"Selecciona la opción","error":_vm.$v.form.type_destiny.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.type_destiny.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.allDestinyOptions},model:{value:(_vm.$v.form.type_destiny.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.type_destiny, "$model", $$v)},expression:"$v.form.type_destiny.$model"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"reference_price"}},[_vm._v(" Precio de Referencia ")]),_c('v-text-field',{attrs:{"placeholder":"Precio de referencia del articulo"},model:{value:(_vm.form.reference_price),callback:function ($$v) {_vm.$set(_vm.form, "reference_price", $$v)},expression:"form.reference_price"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"reference_link"}},[_vm._v(" Link de Referencia ")]),_c('v-textarea',{attrs:{"placeholder":"Link de referencia del articulo/servicio","rows":"3"},model:{value:(_vm.form.reference_link),callback:function ($$v) {_vm.$set(_vm.form, "reference_link", $$v)},expression:"form.reference_link"}})],1),(_vm.showAllForm)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"user"}},[_vm._v(" Carga de archivos ")]),_c('v-file-input',{attrs:{"multiple":"","counter":"","show-size":"","chips":"","placeholder":"Puede adjuntar imágenes o archivos que faciliten identificar el articulo que requiere"},model:{value:(_vm.form.files),callback:function ($$v) {_vm.$set(_vm.form, "files", $$v)},expression:"form.files"}})],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalCreateMaterial}},[_c('small',[_vm._v(" Cancelar ")])]),_c('v-btn',{staticClass:"float-right",attrs:{"color":((_vm.currentFormMaterial ? 'warning' : 'primary') + " text-white"),"small":""},on:{"click":_vm.sendAddMaterial}},[_vm._v(" "+_vm._s(_vm.currentFormMaterial ? "Actualizar Material" : "Agregar Material")+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }