<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalFilesMaterial"
      @click:outside="executeCloseModalFilesMaterial"
    >
      <v-card>
        <div class="p-4 pb-0" v-if="currentFormMaterial">
          <h5>
            Archivos del material: <b>{{ currentFormMaterial.final_name }}</b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <ul v-if="currentFormMaterial && currentFormMaterial.files.length">
              <li v-for="file in currentFormMaterial.files">
                <a :href="file.file_path" target="_blank">
                  <div class="d-flex align-items-center">
                    <v-icon small color="primary" class="mx-2">
                      mdi-file
                    </v-icon>
                    <p class="my-2">
                      {{ file.file_name }}
                    </p>
                  </div>
                </a>
              </li>
            </ul>
            <p v-else class="text-center mt-4">
              Sin Archivos
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalFilesMaterial"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalFileMaterial: {
      type: Function,
      default: () => {},
    },
    currentFormMaterial: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    executeCloseModalFilesMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalFileMaterial($event);
      }
    },
  },
};
</script>

<style></style>
